.audio-recorder{
    background-color: #ebebeb00;
    box-shadow: 0 0px 0px  #ebebeb00;

}


.audio-recorder-svg-color {
    filter:brightness(0) invert(1);
  }
  