.texto-contenedor {
  white-space: nowrap;
  overflow: hidden;
}

.containerLoading {
  background: #eeeeee00;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: 0px 0px 8px #999;
}

.dot1 {
  background: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: inline-block;
  animation: dots 1s ease-in infinite;
  margin-left: 0px;
}

.dot2 {
  background: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: dots 1s 0.25s ease-in infinite;
  display: inline-block;
}

.dot3 {
  background: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: inline-block;
  animation: dots 1s 0.5s ease-in infinite;
}

@keyframes dots {
  0% {
    transform: translateY(100px);
    opacity: 1;
  }

  50% {
    transform: translateY(80px);
    opacity: 0.7;
  }

  60% {
    transfom: translateY(80px);
  }

  100% {
    transform: translateY(100px);
    opacity: 1;
  }

}

.chat-long-text{
  overflow: hidden;
  word-break: break-all;
}


.css-1q673av-MuiButtonBase-root-MuiListItem-root.Mui-selected {
  background-color: rgb(179 226 255 / 91%)!important;
}

.new-chat-icon {
  transform: scale(10);
}

.file_not_found{
  transform: scale(5);
}

.file_container{
  min-height: 100px;
  min-width: 100%;
}