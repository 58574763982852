.Archivo {
  position: relative;
  left: 535px;
  height: 550px;
  width: 100px;
  font-size: calc(1px + 2vmin);
  color: blue;
  
  

}

  
  .App-header {
    position: relative;
    right: 399px;
    height: 2px;
    font-size: calc(10px + 2vmin);
   
    
  }

  .App1 {
    position: relative;
    left: 20px;
    height: 600px;
    
   
  }

  .Boton{
    height: 9px;
    width: 300px;
    
  }

 