.custom-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #f0f0f0;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    margin-right: 8px;
  }
  