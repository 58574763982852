.containerLoading {
    background: #eeeeee00;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 8px #999;
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    position: relative;
}

.dot1, .dot2, .dot3, .dot4 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 5px; /* Espacio entre los puntos */
    display: inline-block;
    animation: dots 1s ease-in infinite;
}

.dot1 {
    background: #4285F4; /* Azul */
    animation: dots 1s ease-in infinite;
}

.dot2 {
    background: #DB4437; /* Rojo */
    animation: dots 1s 0.25s ease-in infinite;
}

.dot3 {
    background: #F4B400; /* Amarillo */
    animation: dots 1s 0.5s ease-in infinite;
}

.dot4 {
    background: #0F9D58; /* Verde */
    animation: dots 1s 0.75s ease-in infinite;
}

@keyframes dots {
    0% {
        transform: translateY(100px);
        opacity: 1;
    }
    50% {
        transform: translateY(80px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(100px);
        opacity: 1;
    }
}

.text {
    position: absolute; 
    font-size: 16px;
    color: #000; 
    top: 15rem;
}